<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/dashboards/analytics">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                (click)="onToggle()">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'active': show}">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/dashboards/analytics" routerLinkActive="active"
                            class="nav-link">{{'navbar.home'|translate}} </a>
                    </li>

                    <li class="nav-item" *ngFor="let item of navigation">
                        <a class="nav-link">{{item.title|translate}} <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"
                                *ngFor="let option of item.children"><a [routerLink]="option.url" class="nav-link"
                                    routerLinkActive="active" (click)="onToggle()"
                                    [routerLinkActiveOptions]="{exact: true}">{{option.title|translate}}</a></li>
                        </ul>
                    </li>

                    <li class="nav-item"><a class="nav-link">{{'navbar.language'|translate}} <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    class="nav-link" type="button" (click)="onChangeLanguage(Languages.es)"
                                    [ngClass]="{'active': AppEnviroment.Translate.currentLang === Languages.es}">Español</a>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    class="nav-link" type="button" (click)="onChangeLanguage(Languages.en)"
                                    [ngClass]="{'active': AppEnviroment.Translate.currentLang === Languages.en}">English</a>
                            </li>
                        </ul>
                    </li>


                </ul>
                <div class="others-options">
                    <div class="option-item" *ngIf="false"><i class="search-btn flaticon-search"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <a [routerLink]="['/']" type="button" class="default-btn" (click)="closeSession()">
                        <i class="fas fa-sign-out-alt"></i>
                        {{'actions.logout'|translate}}
                    </a>
                </div>
            </div>
        </nav>
    </div>
</div>