import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorComponent } from "./components/pages/error/error.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { AdminComponent } from "./theme/layout/admin/admin.component";

const routes: Routes = [
    {
        path: "",
        component: AuthComponent,
        children: [
            {
                path: "",
                redirectTo: "auth/signin",
                pathMatch: "full",
            },
            {
                path: "auth",
                loadChildren: () =>
                    import("./views/authentication/authentication.module").then(
                        (module) => module.AuthenticationModule
                    ),
            },
        ],
    },
    {
        path: "",
        component: AdminComponent,
        children: [
            {
                path: "wizards",
                loadChildren: () =>
                    import("./views/wizards/wizards.module").then(
                        (module) => module.WizardsModule
                    ),
            },
            {
                path: "dashboards",
                loadChildren: () =>
                    import("./views/dashboards/dashboards.module").then(
                        (module) => module.DashboardsModule
                    ),
            },
            {
                path: "financial-plan",
                loadChildren: () =>
                    import("./views/financial-plan/financial-plan.module").then(
                        (module) => module.FinancialPlanModule
                    ),
            },
            {
                path: "customers",
                loadChildren: () =>
                    import("./views/customers/customers.module").then(
                        (module) => module.CustomersModule
                    ),
            },
            {
                path: "simulations",
                loadChildren: () =>
                    import("./views/simulations/simulations.module").then(
                        (module) => module.SimulationsModule
                    ),
            },
        ],
    },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
