import { Pipe, PipeTransform } from "@angular/core";
import { KeyValuePair } from "zoftinium-frontend";

@Pipe({
  name: "typesToString",
})
export class TypesToStringPipe implements PipeTransform {
  transform(key: any, types: KeyValuePair[], prop, field): string {
    const type = types.find((t) => t[prop] === key);
    return type ? type[field] : key;
  }
}
