import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CustomerBusinessService } from "@business/customer/customer-business.service";
import { PublicBusinessService } from "@business/public/public-business.service";
import { UiModalComponent } from "@components/modal/ui-modal/ui-modal.component";
import { AppEnviroment } from "@model/app-enviroment";
import { Terms } from "@model/terms";
import Swal from "sweetalert2";

@Component({
    selector: "app-terms",
    templateUrl: "./terms.component.html",
    styleUrls: ["./terms.component.scss"],
})
export class TermsComponent implements OnInit {
    @ViewChild("ModalWindow", { static: false }) modalWindow: UiModalComponent;
    @Output() closed = new EventEmitter<boolean>();
    @Input() showClose = false;
    @Input() hideButtons = false;

    currentTerms: Terms;
    constructor(
        private readonly router: Router,
        private readonly businessCustomer: CustomerBusinessService,
        private readonly business: PublicBusinessService,
        readonly sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.getTerms();
    }

    onClose() {
        this.closed.emit(false);
        this.modalWindow.hide();
    }

    onAccept() {
        this.businessCustomer.AcceptTerms();
        this.onClose();
        this.goToInside();
    }

    onReject() {
        this.onClose();
        Swal.fire({
            title: AppEnviroment.Translate.instant("notify.info"),
            icon: "warning",
            text: AppEnviroment.Translate.instant("notify.unAcceptTerms"),
        });
    }
    goToInside() {
        this.router.navigate(["/wizards/account"]);
    }

    getTerms() {
        this.business
            .GetTerms()
            .then((terms) => {
                if (!terms)
                    throw "No existen Términos y condiciones, comunícate con nosotros";

                this.currentTerms = { ...terms };
                this.modalWindow.show();
            })
            .catch((x) =>
                Swal.fire(
                    AppEnviroment.Translate.instant("notify.error"),
                    "" + x,
                    "error"
                )
            );
    }
}
