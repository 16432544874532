<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Captura patrimonio</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section ptb-100">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                      
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="" name="EMAIL">
                        <button type="submit">Nueva Simulación</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-4">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Crédito A</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Ver</a>
                    <a routerLink="/services-details" class="read-btn text-danger"
                        style="margin-left: 5em;">Eliminar</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Crédito B</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Ver</a>
                    <a routerLink="/services-details" class="read-btn text-danger"
                        style="margin-left: 5em;">Eliminar</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Crédito C</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Ver</a>
                    <a routerLink="/services-details" class="read-btn text-danger"
                        style="margin-left: 5em;">Eliminar</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Crédito D</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Ver</a>
                    <a routerLink="/services-details" class="read-btn text-danger"
                        style="margin-left: 5em;">Eliminar</a>
                </div>
            </div>


        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore
                magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne"
                                aria-expanded="false" aria-controls="collapseOne">What access do I have on the free
                                plan? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What access do I
                                have on a free trial? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Does the price
                                go up as my team gets larger? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How can I cancel
                                my subscription? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx"
                                href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Can I pay via an
                                Invoice? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>