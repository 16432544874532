import { Component, OnInit } from "@angular/core";
import { UserBusinessService } from "src/app/business/user/user-business.service";
import { AppEnviroment } from "src/app/model/app-enviroment";
import Swal from "sweetalert2";
import { Navigation, NavigationItem } from "../navigation";
declare var $;

@Component({
    selector: "app-navbar-one",
    templateUrl: "./navbar-one.component.html",
    styleUrls: ["./navbar-one.component.scss"],
    providers: [NavigationItem],
})
export class NavbarOneComponent implements OnInit {
    public navigation: Navigation[] = [];
    AppEnviroment = AppEnviroment;
    Languages = Languages;
    show = false;
    constructor(
        public nav: NavigationItem,
        private userBusiness: UserBusinessService
    ) {
        this.navigation = this.nav.get();
    }

    ngOnInit(): void {}

    onToggle() {
        setTimeout(() => {
            this.show = !this.show;
        }, 300);
    }

    closeSession() {
        AppEnviroment.CloseSession();
    }

    onChangeLanguage(language: Languages) {
        AppEnviroment.Translate.use(language);
        this.userBusiness
            .ChangeLanguage(language)
            .catch((x) => Swal.fire("Error", "" + x, "error"));
    }
}

export enum Languages {
    es = "es",
    en = "en",
}
