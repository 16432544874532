import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { HomeFiveComponent } from "./components/pages/home-five/home-five.component";
import { HomeSixComponent } from "./components/pages/home-six/home-six.component";
import { HomeSevenComponent } from "./components/pages/home-seven/home-seven.component";
import { HomeEightComponent } from "./components/pages/home-eight/home-eight.component";
import { NavbarOneComponent } from "./theme/layout/admin/navigation/navbar-one/navbar-one.component";
import { NavbarTwoComponent } from "./components/layouts/navbar-two/navbar-two.component";
import { NavbarThreeComponent } from "./components/layouts/navbar-three/navbar-three.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { ProjectsOneComponent } from "./components/pages/projects-one/projects-one.component";
import { ProjectsTwoComponent } from "./components/pages/projects-two/projects-two.component";
import { ProjectsDetailsComponent } from "./components/pages/projects-details/projects-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { LogInComponent } from "./views/authentication/log-in/log-in.component";
import { SignUpComponent } from "./components/pages/sign-up/sign-up.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { SharedModule } from "./theme/shared/shared.module";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { Ng2GoogleChartsModule } from "ng2-google-charts";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        NavbarOneComponent,
        NavbarTwoComponent,
        NavbarThreeComponent,
        AboutComponent,
        ServicesOneComponent,
        ServicesTwoComponent,
        ServicesDetailsComponent,
        ProjectsOneComponent,
        ProjectsTwoComponent,
        ProjectsDetailsComponent,
        ContactComponent,
        BlogGridComponent,
        BlogRightSidebarComponent,
        BlogDetailsComponent,
        PricingComponent,
        TeamComponent,
        FaqComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ErrorComponent,
        // LogInComponent,
        SignUpComponent,
        ComingSoonComponent,
        AuthComponent,
        AdminComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        TranslateModule.forRoot({
            defaultLanguage: "es",
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-right",
            preventDuplicates: true,
            autoDismiss: true,
        }),
        Ng2GoogleChartsModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
