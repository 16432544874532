import { Component, OnInit } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { AppEnviroment } from "./model/app-enviroment";
import { TranslateService } from "@ngx-translate/core";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router,
        private translate: TranslateService
    ) // private appEnviroment: AppEnviroment
    {
        AppEnviroment.Translate = translate;
        AppEnviroment.Translate.addLangs(["en", "es"]);
        AppEnviroment.Translate.setDefaultLang("es");
        AppEnviroment.Translate.use("es");
    }

    ngOnInit() {
        this.recallJsFuntions();
        AppEnviroment.LoadConfiguration();
    }

    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $(".preloader-area").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/main.js");
                $(".preloader-area").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
