import { DecimalPipe } from "@angular/common";
import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[appDecimalTextInputFormatter]",
})
export class DecimalTextInputFormatterDirective {
    @Input() digitsInfo = 0;
    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef,
        private decimalPipe: DecimalPipe
    ) {
        this.el = this.elementRef.nativeElement;
    }

    private buildValue(value) {
        value = value.replace(/\,/g, "");

        if (value && !isNaN(value)) {
            let num: number = value;

            let result = this.decimalPipe.transform(Number(num));

            if (this.digitsInfo === 0) {
                this.el.value = result;
                return;
            }

            // if last char is a dot
            if ((value as string).toString().endsWith(".")) {
                this.el.value = `${result}.`;
                return;
            }

            if ((value as string).toString().includes(".")) {
                const parts = value.toString().split(".");
                const intPart = +parts[0];
                const decimalPart = parts[1];
                result = this.decimalPipe.transform(Number(intPart));
                this.el.value = `${result}.${decimalPart}`;
                return;
            }
            this.el.value = result;
        }
    }

    @HostListener("input", ["$event"])
    onInput(event) {
        let value = event.target.value;
        this.buildValue(value);
    }

    @HostListener("focus", ["$event"])
    onFocus(event) {
        let value = event.target.value;
        this.buildValue(value);
    }
}
