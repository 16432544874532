<div class="row">
    <ng-container *ngIf="items.length > 0;else empty">
        <div class="col-lg-4 col-md-6 mt-3 pb-0" *ngFor="let item of items | slice:start:end">
            <div class="single-services-box pb-1 h-100" type="button" (click)="onEdit(item)">
                <div class="icon" [ngClass]="item.bgColor">
                    <i class="flaticon-setting"></i>
                </div>
                <h3>{{item.Title}}</h3>
                <p class="lh-sm text-justify">{{item.Observations?.length <= 90 ? item.Observations : (item.Observations
                        | slice:0:90) + "..." }}&nbsp;</p>
                        <a (click)="onEdit(item); $event.stopPropagation();" type="button"
                            class="me-2 read-btn ">{{'actions.see'|
                            translate}}</a>
                        <a (click)="onRemove(item); $event.stopPropagation();" type="button"
                            class="ms-2 read-btn text-danger" *ngIf="!!item.CustomerSimulation">{{'actions.del'|
                            translate}}</a>
                        <p class="small fst-italic text-nowrap lh-base">{{'commonFields.lastUpdate'|translate}}
                        </p>
                        <p class="small fst-italic text-nowrap lh-base">{{item.Edition}}</p>
            </div>
        </div>
    </ng-container>
    <ng-template #empty>
        <div class="col-lg-4 col-md-6 mt-3 pb-0 mx-auto">
            <div class="single-services-box pb-1 h-100">
                <div class="icon">
                    <img src="assets/img/empty-box.png" alt="image" class="img-fluid">
                </div>
                <h3 class="text-transform-none">{{'simulations.noSimulations'|translate}}</h3>
            </div>
        </div>
    </ng-template>
    <div class="col-lg-12 col-md-12">
        <div class="pagination-area" *ngIf="pages.length">
            <a (click)="previousPage()" type="button" class="prev page-numbers"><i class="flaticon-left"></i></a>
            <ng-container *ngFor="let item of pages">
                <a (click)="goToPage(item)" type="button" class="page-numbers "
                    [ngClass]="{'current': item === page}">{{item}}</a>
            </ng-container>
            <a (click)="nextPage()" type="button" class="next page-numbers"><i class="flaticon-right"></i></a>
        </div>
    </div>
</div>