import { Injectable } from "@angular/core";
import { User } from "zoftinium-frontend";
import { AppEnviroment } from "src/app/model/app-enviroment";

export interface Navigation {
    id: string;
    title: string;
    type: "item" | "collapse" | "group";
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
    permission?: string;
}

// export interface Navigation extends NavigationItem {
//     children?: NavigationItem[];
// }

const NavigationItems: Navigation[] = [
    {
        id: "financialPlan",
        title: "navbar.financialPlan",
        type: "group",
        icon: "feather icon-help-circle",
        children: [
            {
                id: "myFinancialPlan",
                title: "navbar.myFinancialPlan",
                icon: "feather icon-home",
                type: "item",
                url: "/financial-plan/slides",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "myFiles",
                title: "navbar.myFiles",
                icon: "feather icon-home",
                type: "item",
                url: "/customers/attach",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "pristineReports",
                title: "navbar.pristineReports",
                icon: "feather icon-home",
                type: "item",
                url: "/financial-plan/reports",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "budgetEntry",
                title: "navbar.budgetEntry",
                icon: "feather icon-home",
                type: "item",
                url: "/financial-plan/budget",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "patrimonyEntry",
                title: "navbar.patrimonyEntry",
                icon: "feather icon-home",
                type: "item",
                url: "/financial-plan/patrimony",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "financialGoals",
                title: "navbar.myGoals",
                icon: "feather icon-home",
                type: "item",
                url: "/financial-plan/goals",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
        ],
    },
    {
        id: "simulate",
        title: "navbar.simulate",
        type: "group",
        icon: "feather icon-help-circle",
        children: [
            {
                id: "creditSimulator",
                title: "navbar.creditSimulator",
                icon: "feather icon-home",
                type: "item",
                url: "/simulations/credit",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "retirementSimulator",
                title: "navbar.retirementSimulator",
                icon: "feather icon-home",
                type: "item",
                url: "/simulations/retirement",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
        ],
    },
    {
        id: "myAccount",
        title: "navbar.myAccount",
        type: "group",
        icon: "feather icon-help-circle",
        children: [
            {
                id: "myProfile",
                title: "navbar.myProfile",
                icon: "feather icon-home",
                type: "item",
                url: "/customers/profile",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "changePassword",
                title: "navbar.changePassword",
                icon: "feather icon-home",
                type: "item",
                url: "/auth/changePassword",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "membership",
                title: "navbar.membership",
                icon: "feather icon-home",
                type: "item",
                url: "/dashboards/membership",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
            {
                id: "welcome",
                title: "navbar.welcome",
                icon: "feather icon-home",
                type: "item",
                url: "/dashboards/welcome",
                classes: "nav-item",
                breadcrumbs: false,
                permission: "*",
            },
        ],
    },
];

@Injectable()
export class NavigationItem {
    user: User;
    navBarItems: any[];
    constructor() {}

    navItems: Navigation[];
    public get() {
        this.navBarItems = AppEnviroment.DeepCopy(NavigationItems);
        this.navItems = [];
        this.navBarItems.forEach((item) => {
            const newGroup = AppEnviroment.DeepCopy(item);
            newGroup.children = this.getChilds(item.children as Navigation[]);
            if (newGroup.children && newGroup.children.length > 0)
                this.navItems.push(newGroup);
        });

        return this.navItems;
    }

    getChilds(items: Navigation[]): Navigation[] {
        if (!items) return null;
        const result: Navigation[] = [];
        items.forEach((item: Navigation) => {
            let newItemNav = item;
            newItemNav.children = this.getChilds(newItemNav.children);
            if (
                newItemNav.type == "collapse" &&
                newItemNav.children.length == 0
            )
                return result;
            if (newItemNav.type == "group" && newItemNav.children.length > 0)
                result.push(newItemNav);
            else result.push(newItemNav);
        });
        return result;
    }
}
