import { Component, EventEmitter, Input, Output } from "@angular/core";
declare let $: any;

@Component({
    selector: "app-card-list",
    templateUrl: "./card-list.component.html",
    styleUrls: ["./card-list.component.scss"],
})
export class CardListComponent {
    private _items: any[] = [];
    @Input() set items(value: any[]) {
        this._items = value.map((r, i) => this.setIconAndColor(r, i));
        this.setPages();
    }
    get items() {
        return this._items;
    }

    @Output() editEvent = new EventEmitter<any>();
    @Output() removeEvent = new EventEmitter<any>();
    pages: number[] = [];
    start = 0;
    pageSize = 9;
    end = this.pageSize;
    page = 1;

    icons = [
        "flaticon-it",
        "flaticon-setting",
        "flaticon-promotion",
        "flaticon-cellphone",
        "flaticon-shopping-cart",
        "flaticon-optimize",
    ];
    bgColor = [
        "",
        "bg-deb0fe",
        "bg-79e8e2",
        "bg-fcc774",
        "bg-84b7fd",
        "bg-fe929f",
    ];

    setPages() {
        this.pages = Array(Math.ceil(this._items.length / this.pageSize))
            .fill(0)
            .map((_, i) => i + 1);
    }

    setIconAndColor(data, index) {
        data.icon = this.icons[index % this.icons.length];
        data.bgColor = this.bgColor[index % this.bgColor.length];
        return data;
    }

    goToPage(page: number) {
        this.page = page;
        this.start = (this.page - 1) * this.pageSize;
        this.end = this.start + this.pageSize;
    }

    nextPage() {
        if (this.page < this.pages.length) {
            this.page++;
            this.goToPage(this.page);
        }
    }

    previousPage() {
        if (this.page > 1) {
            this.page--;
            this.goToPage(this.page);
        }
    }

    onEdit(item: any) {
        $("html, body").animate({ scrollTop: "0" }, 500);
        this.editEvent.emit(item);
    }

    onRemove(item: any) {
        this.removeEvent.emit(item);
    }
}
