import { Injectable } from "@angular/core";
import { ApiGatewayService } from "@business/services/api-gateway.service";
import { Terms } from "@model/terms";
import { ServiceObject } from "zoftinium-frontend";

@Injectable({
    providedIn: "root",
})
export class PublicBusinessService {
    private readonly _module = "Public";
    private readonly _service = "FinancialPlan";

    constructor(private apiGatewaySvc: ApiGatewayService) {}

    private handleResponse<T>(serviceObj: ServiceObject): Promise<T> {
        return this.apiGatewaySvc
            .PostAction(serviceObj)
            .then((x) => {
                serviceObj = x as ServiceObject;
                if (!serviceObj.Success) {
                    throw new Error(serviceObj.Message);
                }
                return serviceObj.Data;
            })
            .catch((x) => {
                throw x.message;
            });
    }

    GetTerms(): Promise<Terms> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetTerms"
        );
        return this.handleResponse(serviceObj);
    }
}
