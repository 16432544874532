import { Pipe, PipeTransform } from "@angular/core";
import { AppEnviroment } from "src/app/model/app-enviroment";

@Pipe({
  name: "clear",
})
export class ClearPipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === "string") {
      return value.replace(/__/g, "");
    }
    return value;
  }
}
