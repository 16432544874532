import { Account, AccountAttachData } from "../../model/account";
import { Injectable } from "@angular/core";
import {
    ActionResponse,
    CatalogValue,
    ServiceObject,
} from "zoftinium-frontend";
import { ApiGatewayService } from "../services/api-gateway.service";
import { WizardState } from "src/app/model/wizard-state";
import { FinancialPlan } from "src/app/model/financial-plan";
import { Guid } from "src/app/model/guid";
import { SlideGroup } from "src/app/model/slide-group";
import { Slide } from "src/app/model/slide";
import { FinancialGoal } from "@model/financial-goal";
import { AppEnviroment } from "@model/app-enviroment";
import { NotificationService } from "@business/general/notification.service";

@Injectable({
    providedIn: "root",
})
export class CustomerBusinessService {
    private readonly _module = "Customers";
    private readonly _service = "FinancialPlan";

    account: Account;

    constructor(
        private apiGatewaySvc: ApiGatewayService,
        private readonly notificationAdapter: NotificationService
    ) {}

    private handleResponse<T>(serviceObj: ServiceObject): Promise<T> {
        return this.apiGatewaySvc
            .PostAction(serviceObj)
            .then((x) => {
                serviceObj = x as ServiceObject;
                if (!serviceObj.Success) {
                    throw new Error(serviceObj.Message);
                }
                return serviceObj.Data;
            })
            .catch((x) => {
                throw x.message;
            });
    }

    GetAccount(): Promise<Account> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetAccount"
        );
        serviceObj.Data = {};
        return this.handleResponse(serviceObj);
    }

    SaveWizardState(wizard: WizardState): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "SaveWizardState"
        );
        serviceObj.Data = { wizard };
        return this.handleResponse(serviceObj);
    }

    SaveAccount(account: Account): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "SaveAccount"
        );
        serviceObj.Data = { account };
        return this.handleResponse(serviceObj);
    }

    GetIndicators(): Promise<FinancialPlan> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetIndicators"
        );
        return this.handleResponse(serviceObj);
    }

    GenerateIndicators(): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GenerateIndicators"
        );
        return this.handleResponse(serviceObj).then((resp: ActionResponse) => {
            const unCompleted =
                !resp.Success &&
                (resp["Data"] as string).includes("UNCOMPLETED");
            if (unCompleted) {
                this.showUnCompletedIndicators();
                const success = { Success: true };
                return success as ActionResponse;
            }
            return resp;
        });
    }

    private showUnCompletedIndicators() {
        this.ValidateCompleteToIndicators().then((resp) => {
            const title = AppEnviroment.Translate.instant("notify.unCompleted");
            const msg = `${AppEnviroment.Translate.instant(
                "notify.unCompletedMessage"
            )} ${resp.Message}`;
            this.notificationAdapter.ShowInfo(title, msg);
        });
    }

    ValidateCompleteToIndicators(): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "ValidateCompleteToIndicators"
        );
        return this.handleResponse(serviceObj);
    }

    GetAccountAttachment(fileId: Guid): Promise<boolean> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetAccountAttachment"
        );
        serviceObj.Data = { fileId };
        return this.apiGatewaySvc.GetFileAction(serviceObj);
    }

    DeleteAccountAttachment(fileId: Guid): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "DeleteAccountAttachment"
        );
        serviceObj.Data = { fileId };
        return this.handleResponse(serviceObj);
    }

    SaveAccountAttachment(attach: AccountAttachData): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "SaveAccountAttachment"
        );
        serviceObj.Data = { attach };
        return this.handleResponse(serviceObj);
    }

    SaveAccountAttachmentFile(
        attach: AccountAttachData,
        file: File
    ): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "SaveAccountAttachment"
        );
        serviceObj.Data = { attach };
        return this.apiGatewaySvc
            .PostFileAction(serviceObj, "file", file)
            .then((x) => {
                serviceObj = x as ServiceObject;
                if (!serviceObj.Success) throw new Error(serviceObj.Message);
                return x.Data as ActionResponse;
            })
            .catch((x) => {
                throw x;
            });
    }

    GetSlides(): Promise<SlideGroup> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetSlides"
        );
        return this.handleResponse(serviceObj);
    }

    GetSlide(slideId): Promise<Slide> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetSlide"
        );
        serviceObj.Data = { slideId };
        return this.handleResponse(serviceObj);
    }

    SaveFinancialGoals(goals: FinancialGoal[]): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "SaveFinancialGoals"
        );
        serviceObj.Data = { goals };
        return this.handleResponse(serviceObj);
    }

    AcceptTerms() {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "AcceptTerms"
        );
        return this.handleResponse(serviceObj);
    }

    async GetPaidTypes(): Promise<CatalogValue[]> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "GetPaidTypes"
        );
        return this.handleResponse(serviceObj);
    }
}
