<div class="card" [ngClass]="cardClass" #toAnimate animates animatesInitMode="show">
  <div class="card-header" *ngIf="!hidHeader" [ngClass]="headerClass">
    <h5 *ngIf="!customHeader">{{ cardTitle }}</h5>
    <p *ngIf="!customHeader && cardCaption" [ngClass]="captionClass">{{cardCaption}}</p>
    <div class="card-header-right" *ngIf="this.options && !customHeader">
      <div class="btn-group card-option dropdown">
        <ul class="list-unstyled card-option" style="display: flex;">
          <li class="full-card" style="padding: 5px;" (click)="fullCardToggle(toAnimate, '', true)"><a
              href="javascript:"><span><i class="feather" [ngClass]="fullIcon"></i> {{this.cardClass === 'full-card' ?
                '' : ''}}</span></a></li>
          <li class=" minimize-card" style="padding: 5px;" (click)="collapsedCardToggle()"><a
              href="javascript:"><span><i class="feather" [ngClass]="collapsedIcon"></i> {{this.collapsedCard ===
                'collapsed' ? '' : ''}} </span>
              <span style="display:none"><i class="feather icon-plus"></i></span></a></li>
          <li class=" close-card" style="padding: 5px;" (click)="cardRemoveAction()"><a href="javascript:"><i
                class="feather icon-trash"></i> </a></li>
        </ul>
      </div>
    </div>
    <ng-content *ngIf="customHeader" select=".app-card-header"></ng-content>
  </div>
  <div *ngIf="this.options; else subMenuContent">
    <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
  </div>
  <ng-template #subMenuContent>
    <div class="card-body" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </ng-template>
  <div *ngIf="isCardFooter" class="card-footer" [ngClass]="footerClass">
    <ng-content select=".app-card-footer"></ng-content>
  </div>
  <div class="card-loader" *ngIf="loadCard"><i class="pct-loader1 anim-rotate"></i></div>
</div>