<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-1">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>{{title|translate}}</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="team-section">
  <div class="container">
    <router-outlet></router-outlet>
  </div>
  <div class="default-shape">
    <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
    <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
    <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
  </div>
</section>