import { Component, OnInit } from "@angular/core";
import { AppEnviroment } from "src/app/model/app-enviroment";
import { Languages } from "../admin/navigation/navbar-one/navbar-one.component";

@Component({
    selector: "app-auth",
    templateUrl: "./auth.component.html",
    styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
    AppEnviroment = AppEnviroment;
    Languages = Languages;
    constructor() {}

    ngOnInit() {}

    onChangeLanguage(language: Languages) {
        AppEnviroment.Translate.use(language);
    }
}
