import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "split",
})
export class SplitPipe implements PipeTransform {
  transform(value: string, char: string, part: number): any {
    return value.split(char)[part];
  }
}
