<app-navbar-two></app-navbar-two>

<div class="main-banner-area-seven">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>Digital Marketing</span>
                            <h1>Digital Service With Excellent Quality</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing incididunt ut laboredolore magna aliqua elsed  tempomet, consectetur adipiscing.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/home-seven/1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/4.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/5.png" class="wow animate__animated animate__bounceIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/6.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/7.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/8.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/9.png" class="wow animate__animated animate__rotateIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/10.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/11.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-seven/main.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<section class="more-features-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="more-features-content">
                    <div class="icon">
                        <i class="flaticon-report"></i>
                    </div>
                    <h3>SEO Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="more-features-content bg-f0fffc">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Social Marketting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="more-features-content bg-fceee2">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Competitor Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="more-features-content bg-fde2ee">
                    <div class="icon">
                        <i class="flaticon-project-management"></i>
                    </div>
                    <h3>Creative Idea</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tech-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="tech-content">
                    <h2>Awesome <span>Things</span> About Tech</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed dood tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.typesetting, remaining essentially unchanged.</p>
                    <div class="tech-btn">
                        <a routerLink="/" class="default-btn">Discover More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="tech-image">
                    <img src="assets/img/tech.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/digital.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3>Digital <span>Marketing</span></h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices  Lorem Ipsum is simply dummy tex printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> SEO Marketing</li>
                        <li><i class="flaticon-check"></i> Email Marketing</li>
                        <li><i class="flaticon-check"></i> Youtube Marketing</li>
                        <li><i class="flaticon-check"></i> Social Marketing</li>
                        <li><i class="flaticon-check"></i> Facebook Marketing</li>
                        <li><i class="flaticon-check"></i> Page Ranking</li>
                    </ul>
                    <div class="digital-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="agency-services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Awesome <span>Services</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img1.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>Web Design</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img2.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>Agency</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img3.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Solutions</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img4.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Software Engineers</h3>
                        <span>Analysis</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img5.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Marketing Agency</h3>
                        <span>Marketing</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img6.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Data Analysis</h3>
                        <span>Explanation</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="experience-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-">
                <div class="experience-inner-area">
                    <div class="experience-content">
                        <h2>Why <span>Choose</span> Us</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>
                    </div>
                    <div class="experience-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h3>First Working Process</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut dolore ipsum.</p>
                    </div>
                    <div class="experience-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h3>Dedicated Team Member</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut dolore ipsum.</p>
                    </div>
                    <div class="experience-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h3>24/7 Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut dolore ipsum.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="experience-image">
                    <img src="assets/img/experience.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Outstanding <span>Digital</span> Experience</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span><i class="flaticon-analysis"></i> Real-Time Analytics</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> Pay-Per-Click</span></a></li>
                <li><a href="#"><span><i class="flaticon-software"></i> Online Marketing</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> Email Marketing</span></a></li>
                <li><a href="#"><span><i class="flaticon-data"></i> Social Marketing</span></a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Real-Time Analytics</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Pay-Per-Click</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Online Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Email Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="tab-content">
                                <h2>Social Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">What access do I have on the free plan? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What access do I have on a free trial? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Does the price go up as my team gets larger? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How can I cancel my subscription? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Can I pay via an Invoice? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section bg-background ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2020</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2020</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>