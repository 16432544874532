import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { KeyValuePair } from "zoftinium-frontend";

@Pipe({
    name: "money",
})
export class MoneyPipe implements PipeTransform {
    constructor(private readonly currencyPipe: CurrencyPipe) {}

    transform(value: any, moneyFormat: string): string | null {
        const [currencyCode, display, digitsInfo] =
            splitMoneyFormat(moneyFormat);
        return this.currencyPipe.transform(
            value,
            display === "symbol" ? undefined : currencyCode,
            display,
            digitsInfo
        );
    }
}

export const NG_CURRENCY_FORMAT = "FRONT-ANGULAR";
export const SLIDE_FORMAT = "FRONT-SLIDES";
export const SYMBOL_FORMAT = "FRONT-SYMBOL";
export const USD_CODE = "USD";

export const findMoneyFormat = (moneyFormats: KeyValuePair[]) => {
    return moneyFormats
        ? moneyFormats.find((o) => o.Key === NG_CURRENCY_FORMAT).Value
        : "";
};

export const splitMoneyFormat = (moneyFormat: string) => {
    return moneyFormat ? moneyFormat.replace(/'/g, "").split(":") : [];
};
