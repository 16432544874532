<app-ui-modal #ModalWindow [containerClick]="false" [dialogClass]="'modal-xl'" [headerClass]="'bg-primary'"
    [hideFooter]="true" [hideHeader]="true">
    <div class="app-modal-body px-md-3">

        <h2 class="swal2-title">{{currentTerms?.Title}}</h2>
        <div class="swal2-html-container" style="display: block; max-height: 380px;"
            [innerHTML]="sanitizer.bypassSecurityTrustHtml(currentTerms?.ValueText)"></div>
        <div class="swal2-actions">
            <button type="button " class="swal2-cancel swal2-styled" data-dismiss="modal " (click)="onClose()"
                *ngIf="showClose">{{'actions.close'|translate}}</button>
            <ng-container *ngIf="!hideButtons">

                <button type="button " class="swal2-deny swal2-styled" data-dismiss="modal "
                    (click)="onReject()">{{'actions.rejectTerms'|translate}}</button>
                <button class="swal2-confirm swal2-styled" type="button" (click)="onAccept()">
                    <span class="btn-text">{{'actions.acceptTerms'|translate}}</span>
                </button>
            </ng-container>
        </div>
    </div>
</app-ui-modal>