import { User } from "zoftinium-frontend";
import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { Account } from "./account";

export class AppEnviroment {
    static ApiEndPoint: string;
    static Home: string;
    static Version =
        "Pristine Capital Group. Todos los derechos reservados 2022. Versión 1.4.9";
    static Translate: TranslateService;

    static SaveStorage<T>(key: string, value: T) {
        const str = window.btoa(
            unescape(encodeURIComponent(JSON.stringify(value)))
        );
        localStorage.setItem(key, str);
    }

    static GetStorage<T>(key: string): T {
        const str = localStorage.getItem(key);
        return JSON.parse(decodeURIComponent(escape(window.atob(str))));
    }

    static get User(): User {
        if (localStorage.getItem("u")) return AppEnviroment.GetStorage("u");
        else return new User();
    }

    static set User(user: User) {
        AppEnviroment.SaveStorage("u", user);
    }

    static get Account(): Account {
        if (localStorage.getItem("a")) return AppEnviroment.GetStorage("a");
        return null;
    }

    static set Account(account: Account) {
        AppEnviroment.SaveStorage("a", account);
    }

    static IsConnected(): boolean {
        return true;
    }

    static LoadConfiguration() {
        AppEnviroment.Home = "/dashboard/analytics";
        const httpClient = new HttpClient(
            new HttpXhrBackend({ build: () => new XMLHttpRequest() })
        );
        const jsonFile = `assets/config.json`;
        return new Promise<void>((resolve, reject) => {
            httpClient
                .get(jsonFile)
                .toPromise()
                .then((response) => {
                    var result: any = response;
                    AppEnviroment.ApiEndPoint = result.ApiEndPoint;
                    resolve();
                })
                .catch((response: any) => {
                    reject(
                        `No puede cargar el archivo de configuración: ' ${JSON.stringify(
                            response
                        )}`
                    );
                });
        });
    }

    static CloseSession() {
        localStorage.clear();
    }

    static DeepCopy<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    static FormatDate(d: Date = new Date()): string {
        return `${d.getFullYear()}/${(d.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0")}/${d.getDate().toString().padStart(2, "0")} ${d
            .getHours()
            .toString()
            .padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`;
    }
}
