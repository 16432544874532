import { Injectable } from "@angular/core";
import { Languages } from "src/app/theme/layout/admin/navigation/navbar-one/navbar-one.component";
import { ActionResponse, ServiceObject } from "zoftinium-frontend";
import { ApiGatewayService } from "../services/api-gateway.service";

@Injectable({
    providedIn: "root",
})
export class UserBusinessService {
    private readonly _module = "User";
    private readonly _service = "Users";

    constructor(private apiGatewaySvc: ApiGatewayService) {}

    private handleResponse<T>(serviceObj: ServiceObject): Promise<T> {
        return this.apiGatewaySvc
            .PostAction(serviceObj)
            .then((x) => {
                serviceObj = x as ServiceObject;
                if (!serviceObj.Success) {
                    throw new Error(serviceObj.Message);
                }
                return serviceObj.Data;
            })
            .catch((x) => {
                throw x.message;
            });
    }

    ChangeLanguage(language: Languages): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "ChangeLanguage"
        );
        serviceObj.Data = { language };
        return this.handleResponse(serviceObj);
    }

    ChangePassword(
        userId: number,
        password: string,
        newPassword: string
    ): Promise<ActionResponse> {
        let serviceObj = new ServiceObject(
            this._service,
            this._module,
            "ChangePassword"
        );
        serviceObj.Data = { userId, password, newPassword };
        return this.handleResponse(serviceObj);
    }
}
