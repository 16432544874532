import { Injectable } from "@angular/core";
import { AppEnviroment } from "src/app/model/app-enviroment";
import {
    ToastService,
    ToastyType,
} from "src/app/theme/shared/components/toast/toast.service";
import Swal, { SweetAlertOptions } from "sweetalert2";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(private readonly toast: ToastService) {}

    ConfirmToDelete(msg: string) {
        const options: SweetAlertOptions = {
            text: `${AppEnviroment.Translate.instant("notify.confirmDelete", {
                name: msg,
            })}`,
            title: `${AppEnviroment.Translate.instant("notify.confirm")}`,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonText: "No",
        };
        return Swal.fire(options).then((confirm) => {
            if (!confirm.dismiss) {
                return true;
            }
            return false;
        });
    }

    ShowError(msg: string) {
        return Swal.fire("Error", "" + msg, "error");
    }

    ShowInfo(title: string, msg: string) {
        return Swal.fire(title, "" + msg, "info");
    }

    showInvalidForm() {
        this.toast.AddToast(
            "Error",
            AppEnviroment.Translate.instant("errors.formInvalid"),
            10,
            ToastyType.error,
            true
        );
    }

    toastError(message: string) {
        this.toast.AddToast("Error", "" + message, 10, ToastyType.error, true);
    }

    toastInfo(message: string) {
        this.toast.AddToast(
            AppEnviroment.Translate.instant("notify.info"),
            "" + message,
            10,
            ToastyType.info,
            true
        );
    }
}
