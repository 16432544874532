<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area" *ngIf="false">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{'footer.aboutUs'|translate}}</h3>
                    </div>
                    <p>{{'footer.aboutUsText'|translate}}</p>
                    <ul class="footer-social">
                        <li><a href="https://www.facebook.com/pristinecapitalgroup/" target="_blank"
                                rel="noreferrer noopener"><i class="flaticon-facebook"></i></a>
                        </li>
                        <li><a href="https://twitter.com/PristineCP" target="_blank" rel="noreferrer noopener"><i
                                    class="flaticon-twitter"></i></a>
                        </li>
                        <li><a href="https://www.instagram.com/pristinecapitalcol/" target="_blank"
                                rel="noreferrer noopener"><i class="flaticon-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{'footer.importantLinks'|translate}}</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a href="https://pristinecapital.com.co/quienes-somos/" target="_blank" rel="noreferrer
                                noopener">{{'footer.aboutUs'|translate}}</a></li>
                        <li><a href="https://pristinecapital.com.co" target="_blank" rel="noreferrer
                                noopener">{{'footer.pristine'|translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{'footer.services'|translate}}</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a href="https://pristinecapital.com.co/finanzas-personales/" target="_blank"
                                rel="noreferrer noopener">{{'footer.personal'|translate}}</a>
                        </li>
                        <li><a href="https://pristinecapital.com.co/asesoria-financiera-para-parejas/" target="_blank"
                                rel="noreferrer noopener">{{'footer.couple'|translate}}</a>
                        </li>
                        <li><a href="https://pristinecapital.com.co/portafolios-de-inviersion/ " target="_blank"
                                rel="noreferrer noopener">{{'footer.investment'|translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{'footer.contact'|translate}}</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>{{'footer.phone'|translate}}</h3>
                        <span><a href="tel:+573506795166">+573506795166</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>{{'footer.email'|translate}}</h3>
                        <span><a
                                href="mailto:contacto@pristinecapital.com.co">contacto@pristinecapital.com.co</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>{{'footer.address'|translate}}</h3>
                        <span><a href="javascript:">Carrera 19 # 100- 45 piso 10 Bogotá
                                Colombia</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <!-- <p>© Avrax is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p> -->
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a href="javascript:" (click)="onShowTerms()">{{'footer.terms'|translate}}</a></li>
                        <!-- <li><a routerLink="/privacy-policy">Privacy Policy</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>
<app-terms *ngIf="showTerms" [hideButtons]="true" [showClose]="true" (closed)="showTerms = false"></app-terms>
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer
                    experience are the heart of successful communication. No fake products and services. The customer is
                    king, their lives and needs are the inspiration.</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contact</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control"
                                    placeholder="Your Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control"
                                    placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                    placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6"
                                    placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:contacto@pristinecapital.com.co">contacto@pristinecapital.com.co</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>