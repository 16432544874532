import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "./components/toast/toast.service";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "./components/card/card.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { SplitPipe } from "./pipes/split.pipe";
import { TypesToStringPipe } from "./pipes/types-to-string.pipe";
import { ClearPipe } from "./pipes/clear.pipe";
import { ModalModule } from "./components/modal/modal.module";
import { CardListComponent } from "./components/card-list/card-list.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MoneyPipe } from "./pipes/money.pipe";
import { TermsComponent } from "./components/terms/terms.component";
import { DecimalTextInputFormatterDirective } from "./directives/decimal-text-input-formatter.directive";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        NgSelectModule,
        NgbModule,
        ModalModule,
        TranslateModule,
        NgxDatatableModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        CardModule,
        NgSelectModule,
        TranslateModule,
        ModalModule,
        SplitPipe,
        TypesToStringPipe,
        ClearPipe,
        CardListComponent,
        NgxDatatableModule,
        MoneyPipe,
        TermsComponent,
        DecimalTextInputFormatterDirective,
    ],
    declarations: [
        SplitPipe,
        TypesToStringPipe,
        ClearPipe,
        CardListComponent,
        MoneyPipe,
        TermsComponent,
        DecimalTextInputFormatterDirective,
    ],
    providers: [ToastService, CurrencyPipe],
})
export class SharedModule {}
