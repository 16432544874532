import { EventEmitter, Injectable, Output } from "@angular/core";
import { ToastrService, IndividualConfig } from "ngx-toastr";
interface Toast {
    id: string;
    delay: number;
}

@Injectable()
export class ToastService {
    // @Output() toggleToast: EventEmitter<Toast> = new EventEmitter();
    constructor(private toastrService: ToastrService) {}

    // toast(event) {
    //   this.toggleToast.emit(event);
    // }

    AddToast(
        title: string,
        message: string,
        timeout,
        type: ToastyType,
        closeOther = true
    ) {
        // if (closeOther) {
        //   this.toastyService.clearAll();
        // }

        // const toastOptions = {
        //   title,
        //   message,
        //   // showClose: true,
        //   // timeout: timeout*1000,
        //   // theme: 'bootstrap',
        // };

        switch (type) {
            case ToastyType.default:
            case ToastyType.wait:
            case ToastyType.info:
                this.toastrService.info(message, title);
                break;
            case ToastyType.success:
                this.toastrService.success(message, title);
                break;
            case ToastyType.error:
                this.toastrService.error(message, title);
                break;
            case ToastyType.warning:
                this.toastrService.warning(message, title);
                break;
        }
    }
}

export enum ToastyType {
    default,
    info,
    success,
    wait,
    error,
    warning,
}
