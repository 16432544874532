<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>
            <div class="collapse navbar-collapse">

                <ul class="navbar-nav">

                    <li class="nav-item"><a class="nav-link">{{'auth.language'|translate}} <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a class="nav-link" type="button" (click)="onChangeLanguage(Languages.es)"
                                    [ngClass]="{'active': AppEnviroment.Translate.currentLang === Languages.es}">
                                    <img class="img-fluid me-2" src="../../../../assets/img/es.svg" alt="spanish flag">
                                    {{'commonFields.spanish'|translate}}
                                </a>
                            </li>

                            <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
                                <a class="nav-link" type="button" (click)="onChangeLanguage(Languages.en)"
                                    [ngClass]="{'active': AppEnviroment.Translate.currentLang === Languages.en}">
                                    <img class="img-fluid me-2" src="../../../../assets/img/us.svg" alt="english flag">
                                    {{'commonFields.english'|translate}}</a>
                            </li>
                        </ul>
                    </li>


                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="main-banner-area-three">
    <div class="d-table">
        <div class="d-table-cell align-baseline ">
            <div class="container mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12 mt-md-5">
                        <router-outlet></router-outlet>
                    </div>
                    <div class="col-lg-7 col-md-12">
                        <img src="assets/img/source/finance_20.svg" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>