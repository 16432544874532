import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { NavigationItem } from "./navigation/navigation";

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
    styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
    titles = {
        analytics: "dashboard.title",
        account: "wizard.title",
        welcome: "navbar.welcome",
        slides: "financialPlan.title",
        reports: "financialPlan.reportsPristine",
        budget: "financialPlan.budgetEntry",
        patrimony: "financialPlan.patrimonyEntry",
        attach: "customers.accountAttachTitle",
        retirement: "simulations.retirement",
        credit: "simulations.credit",
        profile: "customers.profileTitle",
        goals: "dashboard.goalsFinancialPlan",
    };
    title: string = "";
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.activatedRoute.url.subscribe((_) => {
            this.setTitle();
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((_) => {
                this.setTitle();
            });
    }

    setTitle() {
        const parts = this.router.url.split("/");
        const lastPart = parts[2];
        this.title = this.titles[lastPart];
    }
}
